<template>
    <div class="resources-container" ref="rc"
        >
        <div v-for="(resource, i) in scheduleList" :key="i" class="resource">
            <!-- <div class="calendar-body">
                {{ resource.shop_name }}
            </div> -->
            <div v-for="(shift, j) in resource.shifts" :key="j" class="days-container">
                <div v-if="j < 8" class="cell-day-container"
                    @click="$emit('saveInfo', shift.morning, shift.afternoon, shift.evening, resource.shop_name, shift.date, target, resource.shop_id, resource.scheduling_id, visible)">
                    <Shift :day="shift.date" :resource="resource.shop_name" :morning="shift.morning" :afternoon="shift.afternoon" :evening="shift.evening" @shiftTime="shiftTime" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import Shift from './Shift.vue';
import dayjs from 'dayjs';
// import ShopService from '../../services/shopService';

</script>

<script>

export default {
    name: 'Scheduler-Resource',
    components: {
        Shift,
    },
    props: {
        scheduleList : {
            default : null,
            type : Array
        },
        options: {
            resources: {
                default: null,
                type: Object
            },
            month: {
                default: dayjs().format('MMMM'),
                type: String
            },
            year: {
                default: null,

            },
            start: {
                default: dayjs().format('YYYY-mm-dd'),
                type: Date,
            },
            events: {
                default: null,
                type: Object
            },
            width: {
                default: '100%',
                type: String
            },
            height: {
                default: '100%',
                type: String
            },
        }
    },
    data() {
        return {
            // scheduleList: [],
            today: dayjs().format('DD-MM-YYYY'),
            target: null,
            visible: false,
            length: 0,
            scrollTop: 0,
            scrollLeft: 0,
            top: 0,
            scrollWidth: 0
        }
    },
    emits: ['saveInfo'],
    methods: {
        getDate(index) {
            this.scheduleList[index].shifts
        },
        shiftTime(target) {

            document.querySelectorAll('.morning-shift-container').forEach(node => {
                if (node.contains(target)) {
                    this.target = 'morning'
                }
            })

            document.querySelectorAll('.afternoon-shift-container').forEach(node => {
                if (node.contains(target)) {
                    this.target = 'afternoon'
                }
            })

            document.querySelectorAll('.evening-shift-container').forEach(node => {
                if (node.contains(target)) {
                    this.target = 'evening'
                }
            })

            this.visible = true
        },
        minMax(){
            if(window.innerWidth >= 768){
                return '330px'
            }else{
                return '250px'
            }
        }
    },
    computed: {
        // async shopName(id){
        //     return await ShopService.getShop(localStorage.getItem('token'), id);
        // },
        
    },
    watch: {
        scheduleList(value){
            this.length = value.length
        },
        scrollTop(value){
            document.querySelectorAll('.calendar-body').forEach((body) => {
                body.style.transform = `translateY(${value}px)`
            })
        },
        scrollLeft(value){
            if(value > 0 && value < (this.$refs.rc.clientWidth - this.scrollWidth)){
                document.querySelectorAll('.resource').forEach((resource) => {
                    resource.style.transform = `translateX(-${value}px)`
                })
            }
        }
    },
    mounted() {

        document.querySelector('.scheduler').addEventListener('scroll', (el) => {
            this.scrollTop = el.target.scrollTop
            this.scrollLeft = el.target.scrollLeft
            this.scrollWidth = el.target.scrollWidth
        })
    },
}

</script>