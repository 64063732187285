<template>
    <!-- {{ shift }} -->
    <div :id="id" class="week-day">{{ `Oggi, ${formattedDay}` }}</div>
    <div :id="resource" class="calendar-day" :class="day" @click="$emit('shiftTime', $event.target)">
        <div v-if="morning.time_start !== null" class="morning-shift-container" :class="event(morning.worker)" @click="$emit('selectPeriod', morning)">
            
                <div class="morning-shift">
                    <div v-if="morning.time_start !== null" class="shift">
                        <div class="shift-status">

                        </div>
                        <div class="shift-time">
                            {{ morning.time_start + ' ' +  morning.time_end }}
                        </div>
                    </div>
                    <div class="worker">
                        <div class="name">
                            {{ morning.worker }}
                        </div>
                        <div class="address">
                            {{ address(morning.id) }}
                        </div>
                    </div>
                </div>
        </div>
        <div v-if="afternoon.time_start !== null" class="afternoon-shift-container" :class="event(afternoon.worker)" @click="$emit('selectPeriod', afternoon)">
                    
                <div class="afternoon-shift">
                    <div v-if="afternoon.time_start !== null" class="shift">
                        <div class="shift-status">

                        </div>
                        <div class="shift-time">
                            {{ afternoon.time_start + ' ' +  afternoon.time_end }}
                        </div>
                    </div>
                    <div class="worker">
                        <div class="name">
                            {{ afternoon.worker }}
                        </div>
                        <div class="address">
                            {{ address(afternoon.id) }}
                        </div>
                    </div>
                </div>
        </div>
        <div v-if="evening.time_start !== null" class="evening-shift-container" :class="event(evening.worker)" @click="$emit('selectPeriod', evening)">
            
                <div class="evening-shift">
                    <div v-if="evening.time_start !== null" class="shift">
                        <div class="shift-status">

                        </div>
                        <div class="shift-time">
                            {{ evening.time_start + ' ' +  evening.time_end }}
                        </div>
                    </div>
                    <div class="worker">
                        <div class="name">
                            {{ evening.worker }}
                        </div>
                        <div class="address">
                            {{ address(evening.id) }}
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs';
import { getShop } from '../../getters';

export default {
    name: 'Single-Shift',
    props: {
        day: {
            default: null,
            type: String
        },
        resource: {
                default: null,
                type: String
        },
        morning: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        },
        afternoon: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        },
        evening: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        }
    },
    emits: ['selectPeriod'],
    methods: {
        event(elem){
            if(elem !== null){
                return ' event';
            }else{
                return '';
            }
        },
        address(id){
            var shop = getShop(id);
            return shop.address
        }
    },
    computed : {
        formattedDay(){
            var day = dayjs().locale('it').format('dddd DD MMMM')
            day = day.substring(0, 1).toUpperCase() + day.substring(1, day.length)
            return day
        },
        id(){
            var day = dayjs().locale('it').format('DD-MM')
            return day
        }
    }
}

</script>