class LocalStorage {

    getToken(){
        localStorage.getItem('token')
    }

    setToken(token){
        localStorage.setItem('token', token)
    }
    
}

export default new LocalStorage()