<template>
    <div class="input-container" :class="iconPosition">
        <label v-if="labelText !== null" :for="name">
            {{ labelText }}
        </label>
        <div class="icon-container">
            <svg :class="iconClass">
                <use :xlink:href="iconId"></use>
            </svg>
        </div>
        <input :name="name" :type="type" :class="[inputState, noIcon]" :disabled="disabled" :placeholder="placeholder" 
               :value="value" @input="$emit('update:value', $event.target.value)" @keyup.enter="$emit('login')">

        <div v-for="error of errors" :key="error.$uid" class="error">
            {{ error.$message }}
        </div>
    </div>
</template>

<script>

export default {
    name : 'form-field',
    props : {
        name : {
            default : '',
            type : String
        },
        type : {
            default : 'text',
            type : String
        },
        inputState : {
            default : '',
            type : String
        },
        disabled : {
            default : false,
            type : Boolean
        },
        icon : {
            default : '',
            type : String
        },
        iconRight : {
            default : false,
            type : Boolean
        },
        placeholder : {
            default : '',
            type : String
        },
        labelText : {
            default : null,
            type : String
        },
        value : {
            default : '',
            type : String
        },
        errors : {
            default : null,
            type : String
        }
    },
    emits : ['update:value'],
    data(){
        return{
            model : this.value
        }
    },
    computed : {
        iconId(){
            return '#' + this.icon
        },
        iconClass(){
            return 'icon icon-' + this.icon
        },
        noIcon(){
            if(this.icon == ''){
                return ' no-icon'
            }else{
                return ''
            }
        },
        iconPosition(){
            if(this.iconRight){
                return 'icon-right'
            }else{
                return ''
            }
        }
    },
}

</script>