import axios from "axios";
import StatusService from "./statusService";

class Password{

    constructor(){

        this.url = (process.env.NODE_ENV == 'development' || window.location.origin == 'https://informitalia-client-test.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com')
        ? 'https://informitalia-server-test.quattrolinee.com/api/password'
        : 'https://informitalia-server.quattrolinee.com/api/password'

    }

    async mail(mail){
        
        return await axios.post(`${this.url}/email`, mail)

        .then(function(response){

            var result = {
                success: response.data.success
            }
            
            console.log(response.data)
            return result
        })
        
        .catch(function (error){

            var result = {
                message : error.response.data.message,
                success : false
            }

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return result
        })
    }

    async reset(data){

        return await axios.post(`${this.url}/reset`, data)

        .then(function(response){

            var result = {
                success: response.data.success
            }
            
            console.log(response.data)
            return result
        })
        
        .catch(function (error){

            var result = {
                message : error.response.data.message,
                success : false
            }

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return result
        })
    }
}

export default new Password()