<template>
    <div class="menu" :class="openMenu()">
        <nav class="navbar">
            <div class="app-title">Informitalia</div>
            <div class="hamburger" @click="(close) ? close = false : close = true">
                <div class="square">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </nav>
        <div class="menu-items">
            <!-- <img class="house-icon" src="../../public/assets/images/svg/house.svg" alt="" /> -->
            <router-link @click="close = true" to="/">
                Dashboard 
                <svg class="icon icon-arrow-back">
                    <use xlink:href="#arrow-back"></use>
                </svg>
            </router-link>
            <router-link @click="close = true" to="/turni-programmati">
                Turni Programmati
                <svg class="icon icon-arrow-back">
                    <use xlink:href="#arrow-back"></use>
                </svg>
            </router-link>
            <router-link @click="close = true" to="/profilo">
                Profilo
                <svg class="icon icon-arrow-back">
                    <use xlink:href="#arrow-back"></use>
                </svg>
            </router-link>
        </div>
    </div>
</template>

<script setup>

</script>

<script>

export default {
    name: 'app-menu',
    data() {
        return {
            close: true
        }
    },
    methods: {
        openMenu(){
            if(this.close == true){
                return 'close'
            }else{
                return 'open'
            }
        },
        closeMenu(bool){
            this.close = bool
        }
    }
}

</script>