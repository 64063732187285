<template>
    <div :id="id" class="week-day">{{ formattedDay }}</div>
    <div :id="resource" class="calendar-day" :class="day" @click="$emit('shiftTime', $event.target)">
        <div v-if="morning.time_start !== null" class="morning-shift-container" :class="event(morning.worker)">
            <div class="morning-shift">
                <div class="worker">
                    {{ morning.worker }}
                </div>
                <div v-if="morning.time_start !== null" class="shift">
                    <div class="shift-status">

                    </div>
                    <div class="shift-time">
                        {{ morning.time_start + ' - ' +  morning.time_end }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="afternoon.time_start !== null" class="afternoon-shift-container" :class="event(afternoon.worker)">
            <div class="afternoon-shift">
                <div class="worker">
                    {{ afternoon.worker }}
                </div>
                <div v-if="afternoon.time_start !== null" class="shift">
                    <div class="shift-status">

                    </div>
                    <div class="shift-time">
                        {{ afternoon.time_start + ' - ' +  afternoon.time_end }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="evening.time_start !== null" class="evening-shift-container" :class="event(evening.worker)">
            <div class="evening-shift">
                <div class="worker">
                    {{ evening.worker }}
                </div>
                <div v-if="evening.time_start !== null" class="shift">
                    <div class="shift-status">

                    </div>
                    <div class="shift-time">
                        {{ evening.time_start + ' - ' +  evening.time_end }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'

export default {
    name: 'Scheduler-Shift',
    props: {
        day: {
            default: null,
            type: String
        },
        resource: {
                default: null,
                type: String
        },
        morning: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        },
        afternoon: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        },
        evening: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        }
    },
    emits: ['shiftTime'],
    methods: {
        event(elem){
            if(elem !== null){
                return ' event';
            }else{
                return '';
            }
        },
    },
    computed : {
        formattedDay(){
            var day = this.day.split('-').reverse().join('-')
            day = dayjs(day).locale('it').format('dddd DD-MM')
            day = day.substring(0, 1).toUpperCase() + day.substring(1, day.length)
            return day
        },
        id(){
            var day = this.day.split('-').reverse().join('-')
            day = dayjs(day).locale('it').format('DD-MM')
            return day
        }
    }
}

</script>