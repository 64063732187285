<template>
    <div class="heading">
        <div class="back" @click="$emit('back', false)">
            <svg class="icon icon-arrow-back">
                <use xlink:href="#arrow-back"></use>
            </svg>
            <div v-if="!link" class="text">
                {{ back }}
            </div>
            <router-link :to="href" v-if="link" >{{ back }}</router-link>
        </div>
        <div class="title">{{ title }}</div>
    </div>
</template>

<script>

export default {
    name: 'heading-vue',
    props : {
        title : {
            deafult : 'Turno',
            type: String
        },
        back: {
            default: 'Indietro',
            type: String
        },
        link: {
            default: false,
            type: Boolean
        },
        href: {
            default: '/',
            type: String
        }
    }
}

</script>