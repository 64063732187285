<template>
    <div class="button " :class="[buttonClass, statusInfo]">
        <div class="icon-container">
            <svg :class="iconClass">
                <use :xlink:href="iconId"></use>
            </svg>
        </div>
        {{ text }}
    </div>
</template>

<script>

export default {
    name : 'vue-button',
    props : {
        buttonType : {
            default : 'brand',
            type : String
        },
        text : {
            default : '',
            type : String
        },
        rounded : {
            default : false,
            type : Boolean
        },
        icon : {
            default : '',
            type : String
        },
        status : {
            default : null,
            type : String
        }
    },
    computed : {
        buttonClass(){
            if(this.rounded){
                return 'button--' + this.buttonType + ' button--rounded'
            }else{
                return 'button--' + this.buttonType
            }
        },
        iconId(){
            return '#' + this.icon
        },
        iconClass(){
            return 'icon icon-' + this.icon
        },
        statusInfo(){
            if(this.status !== null){
                return ' button--' + this.status
            }else{
                return ''
            }
        }
    }
}

</script>