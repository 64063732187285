<template>
    <div v-if="(scheduleList[0] && scheduleList[0].shifts !== null)" ref="scheduler" class="scheduler" :class="[(panoramic) ? ' panoramic' : '', (!block) ? ' allow-events' : '']">
        <Resource @saveInfo="saveInfo" :scheduleList="scheduleList" />
    </div>
    <div v-else class="scheduler-message">
        Non ci sono turni programmati per questa settimana
    </div>

</template>

<script setup>
import Resource from '../components/scheduler/Resource.vue';
import ShiftsObjects from '../services/shiftsObjects';
import dayjs from 'dayjs';


const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

</script>

<script>

export default {
    name: 'VueScheduler',
    components: {
        Resource,
        // Header,
        // EditShifts,
    },
    props: {
        scheduleDate: {
            default: new Date(),
            type: Date
        },
        weekNumber: {
            default: null,
            type: Number
        },
        year: {
            default: dayjs().day(6).format('YYYY'),
            type: String
        },
        scheduleId: {
            default: null,
            type: Number
        },
        init: {
            default: null,
            type: Array
        },
        panoramic: {
            default: false,
            type: Boolean
        },
        shops: {
            default: true,
            type: Boolean
        },
        home: {
            default: false,
            type: Boolean
        },
        updated: {
            default: false,
            type: Boolean
        },
        updatedScheduleList: {
            default: null,
            type: Array
        },
        worker: {
            default: null,
            type: Number
        },
        block : {
            default : false,
            type : Boolean
        },
        group_id : {
            default: null,
            type: Number
        },
        statusEditable : {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            afternoon: null,
            asyncError: false,
            date: null,
            evening: null,
            morning: null,
            resource: null,
            scheduleError: false,
            scheduleList: [],
            scheduling_id: null,
            shift_id: null,
            shop_id: null,
            target: null,
            timeError: false,
            user_id: null,
            visible: false,
        }
    },
    methods: {
        saveInfo(morning, afternoon, evening, resource, date, target, shop_id, scheduling_id, visible) {

            this.afternoon = afternoon
            this.date = date
            this.evening = evening
            this.morning = morning
            this.resource = resource
            this.scheduling_id = (this.scheduleId !== null) ? this.scheduleId : scheduling_id
            this.shift_id = (target == 'morning') ? morning.shift_id : (target == 'afternoon') ? afternoon.shift_id : evening.shift_id
            this.shop_id = shop_id
            this.target = target
            this.user_id = (target == 'morning') ? morning.id : (target == 'afternoon') ? afternoon.id : evening.id
            this.visible = visible

        },

        close() {
            this.visible = false
        },
        redirect(){
            localStorage.clear()
            this.$router.push('/login')
        }
    },
    watch: {

        async weekNumber(value){
            this.scheduleList = [await ShiftsObjects.getShiftObjectBySingleWorker(localStorage.getItem('token'), this.year, value, this.worker)]
        }
    },
    computed: {
        createShiftList() {
            return JSON.parse(localStorage.getItem('createShiftList'))
        }
    },
    async mounted() {

        // var weekNumber = (this.weekNumber !== null) ? this.weekNumber : dayjs().week()
            
        if(this.worker !== null && this.worker !== '') this.scheduleList = [await ShiftsObjects.getShiftObjectBySingleWorker(localStorage.getItem('token'), this.year, this.weekNumber, this.worker)];

        // console.log(await ShiftsObjects.getShiftObjectBySingleWorker(localStorage.getItem('token'), this.year, 2, 13))
    }
}

</script>