import dayjs from "dayjs";
import ShiftsObjects from "./services/shiftsObjects";
import { getWorker, getShop } from "./getters";

export const daysOffErrorHandler = (response) => {
    if (response !== false) {

        if (response.success !== undefined) {

            if (response.success == false) {
                var worker = getWorker(response.message.user_id)

                var day = `L'operatore ${worker.full_name} ha uno o più turni programmati nel giorno 
                            ${dayjs(response.message.date).locale('it').format('dddd DD-MM-YYYY')} 
                            nelle seguenti attività:`

                var shops = '';

                response.message.groups.map((shop) => {
                    shops += ` ${shop}`
                })

                return {
                    // eslint-disable-next-line
                    error: true,
                    // eslint-disable-next-line
                    message: day + shops,
                    date: dayjs(response.message.date).format('dddd').toLowerCase(),
                    user_id: response.message.user_id
                }
            }

        } else {
            return {
                error: false,
                // eslint-disable-next-line
                updated: true,
                // eslint-disable-next-line
                edit: false
            }
        }
    }
}

export const isDayOff = (array, date) => {

    var day = dayjs(date).format('dddd')

    day = day[0].toLowerCase() + day.substring(1)
    // console.log('anno array: ', array['year'], 'anno date: ' ,dayjs(date).format('YYYY'))
    // console.log(day)

    if (array !== false && array['year'] == dayjs(date).day(6).format('YYYY')) {

        if (array[day] == 1) {
            return true
        } else {
            return false
        }

    } else {
        return false
    }
}

export const userDaysOff = (array, date) => {

    var daysOff = []
    console.log(date)
    console.log(array)

    if (array !== false && array['year'] == dayjs(date).day(6).format('YYYY')) {

        delete array.created_at
        delete array.id
        delete array.updated_at
        delete array.user_id
        delete array.week_number
        delete array.year

        for (var i = 0; i < 7; i++) {

            var day = dayjs(date).add(i, 'day').format('dddd');
            day = day[0].toLowerCase() + day.substring(1)

            var dayOff = dayjs(date).add(i, 'day').locale('it').format('ddd')
            dayOff = dayOff[0].toUpperCase() + dayOff.substring(1)

            if(array[day] == 1) daysOff.push(dayOff);
        }
    } else {
        return false
    }

    return daysOff.reverse()
}

export const singleShift = async (date, year, weekNr, worker) => {
    var result = [];
    var weekShifts = await ShiftsObjects.getShiftObjectBySingleWorker(localStorage.getItem('token'), year, weekNr, worker);
    console.log(weekShifts.shifts)
    if(weekShifts.shifts !== null){
        Array.from(weekShifts.shifts).map((shift) => {
            console.log('data turno: ', shift.date)
            console.log('data oggi: ', date)
            if(shift.date == date ){
                result.push(shift)
            }
        })
    }
    
    return result
}

export const alphabetic = (a, b) => {

    if (a.full_name < b.full_name) {
        return -1
    } else if (a.full_name > b.full_name) {
        return 1
    }
    return 0
}

export const alphaShop = (a, b) => {
    if (a.full_name < b.full_name) {
        return -1
    } else if (a.full_name > b.full_name) {
        return 1
    }
    return 0
}

export const orderByDate = (a, b) => {

    if (dayjs(a.date).unix() < dayjs(b.date).unix()) {
        return -1
    } else if (dayjs(a.date).unix() > dayjs(b.date).unix()) {
        return 1
    }
    return 0
}

export const totHoursCounter = (time_start, time_end) => {

    var hs = parseInt(time_start.substring(0, 2))
    var he = parseInt(time_end.substring(0, 2))
    var ms = parseInt(time_start.substring(3, 5))
    var me = parseInt(time_end.substring(3, 5))

    ms = parseFloat(((ms * 100) / 6000).toFixed(2))
    me = parseFloat(((me * 100) / 6000).toFixed(2))

    return parseFloat(((he + me) - (hs + ms)).toFixed(2))
}

export const formatExcelJson = (json) => {

    Array.from(json).map((item) => {

        if (item.user_name && item.user_id) item.user_id = item.user_name; else item.user_id = getWorker(item.user_id).full_name
        if (item.shop_name && item.shop_id) item.shop_id = item.shop_name; else item.shop_id = getShop(item.shop_id).full_name
        item.tot_hours = totHoursCounter(item.time_start, item.time_end)

        delete item.id
        delete item.scheduling_id
        delete item.period
        delete item.created_at
        delete item.updated_at

        if (item.user_name) delete item.user_name
        if (item.shop_name) delete item.shop_name
    })

    return json
}

export const reportExcelJson = (json, bool) => {

    Array.from(json).map((item) => {

        if (item.user_name && item.user_id) item.user_id = item.user_name; else item.user_id = getWorker(item.user_id).full_name
        if (item.shop_name && item.shop_id) item.shop_id = item.shop_name; else item.shop_id = getShop(item.shop_id).full_name

        
        item.tot_hours = totHoursCounter(item.time_start, item.time_end)

        delete item.id
        delete item.scheduling_id
        delete item.period
        delete item.created_at
        delete item.updated_at

        if (item.user_name) delete item.user_name
        if (item.shop_name) delete item.shop_name
        if (bool == true) delete item.user_id; else delete item.shop_id;
    })

    return json
}

export const endpoint = () => {
    if(process.env.NODE_ENV == 'production') {
        if(window.location.origin == 'https://informitalia-client.quattrolinee.com') return 'https://informitalia-server.quattrolinee.com';
        else if(window.location.origin == 'https://informitalia-client-test.quattrolinee.com') return 'https://informitalia-server-test.quattrolinee.com';
        else return 'https://api.informitalia.info';
    }
    else return 'https://informitalia-server-test.quattrolinee.com';
}