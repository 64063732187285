<template>
	<div class="center stream">
		<!-- :track="paintBoundingBox" -->
		<qrcode-stream @detect="onDetect"></qrcode-stream>
	</div>
	<!-- <div class="result" style="margin:3rem 0rem;">Result: {{ result }}</div> -->
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
	name: "QRreader",
	components: {
		QrcodeStream
	},
	data() {
		return {
			result: null,
		};
	},
	computed: {},
	methods: {
		onDetect(detectedCodes) {
			this.result = JSON.stringify(
				detectedCodes.map(code => code.rawValue)
			)
		}
	},
};
</script>

<style scoped></style>
