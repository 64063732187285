<template>
    <div class="main">
        <Heading :link="true" title="Profilo" back="Dashboard" href="/" />
        <div class="single-shift-container">
            <div class="single-shift">
                <div class="shift-item day">{{ worker.full_name }}</div>
                <div class="shift-item start">
                    <div>Badge</div>
                    <div>{{ worker.badge }}</div>
                </div>
                <div class="shift-item end">
                    <div>Email</div>
                    <div>{{ worker.email }}</div>
                </div>
                <div class="shift-item scheduled">
                    <div>Telefono</div>
                    <div>{{ worker.phone }}</div>
                </div>
                <div class="shift-item start">
                    <div>Indirizzo</div>
                    <div>{{ worker.address }}</div>
                </div>
                <div class="shift-item end">
                    <div>Città</div>
                    <div>{{ worker.city }}</div>
                </div>
                <div class="shift-item scheduled">
                    <div>Automunito</div>
                    <div>{{ (worker.got_car == 0) ? 'No' : 'Sì' }}</div>
                </div>

                <div class="shift-item end">
                    <div>Monte ore</div>
                    <div>{{ `${totHours}/${(worker.num_hours) ? worker.num_hours : ''}` }}</div>
                </div>
                <div class="shift-item scheduled">
                    <div>Turno di riposo</div>
                    <div>{{ dayOff }}</div>
                </div>
                <!-- {{ period }} -->
                <!-- <div class="shift-item fixed"></div>
      <div class="shift-item effective"></div> -->
            </div>

        </div>
    </div>
</template>

<script>

import { getWorker } from '@/getters'
import DaysOffService from '@/services/daysOffService'
import shiftsService from '@/services/shiftsService'
import { userDaysOff } from '@/dataService'
import Heading from '@/components/scheduler/Heading.vue'
import dayjs from 'dayjs'

export default {

    name: 'operator-profile',
    components: {
        Heading
    },
    data() {
        return {
            daysOff: false,
            totHours: null
        }
    },
    computed: {
        worker() {
            var user_id = localStorage.getItem('user_id')
            return getWorker(user_id)
        },
        dayOff() {
            var result = '';
            Array.from(this.daysOff).map((day, index) => {
                if (index < this.daysOff.length - 1) {
                    result += `${day} / `;
                } else {
                    result += day;
                }
            })

            return result
        }
    },
    async mounted() {
        this.daysOff = userDaysOff(await DaysOffService.getUserDaysOff(localStorage.getItem('token'), dayjs().day(6).year(), dayjs().week(), localStorage.getItem('user_id')), dayjs().day(1).format('YYYY-MM-DD'))
        this.totHours = await shiftsService.getWorkerTotHours(localStorage.getItem('token'), dayjs().day(6).year(), dayjs().week(), localStorage.getItem('user_id'))
    }
}

</script>