import axios from "axios";
import StatusService from "./statusService";

class DaysOff{

    constructor(){

        this.url = (process.env.NODE_ENV == 'development' || window.location.origin == 'https://informitalia-client-test.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') 
        ? 'https://informitalia-server-test.quattrolinee.com/api/v1/daysoff'
        : 'https://informitalia-server.quattrolinee.com/api/v1/daysoff'

    }

    async getDaysOff(token){

        var data

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        await axios.get(this.url, config)

        .then(function (response) {

            console.log('DAYS OFF: ', response.data.data)
            data = response.data.data
        })

        .catch(function (error) {

            StatusService.unauthorized(error.response.status)

            if(error.response.data.data == "This day already has shifts."){

                console.log(error.response.data)
                data = error.response.data

            }else{

                data = false
            }
        })

        return data
    }

    async getDayOff(token, id){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + '/' + id, config)

        .then(function (response) {

            return response.data.data
        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getWeekDaysOff(token, year, weekNr){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + `/${year}/${weekNr}`, config)

        .then(function (response) {

            return response.data.data
        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getUserDaysOff(token, year, weekNr, id){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + `/${year}/${weekNr}/${id}`, config)

        .then(function (response) {

            return response.data.data[0]
        })

        .catch(function (error) {

            // console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async addDayOff(token, dayOffData) {

        var data;

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        await axios.post(this.url, dayOffData, config)

        .then(function (response) {

            console.log('DAYS OFF: ', response.data.data)
            data = response.data.data

        })

        .catch(function (error) {

            StatusService.unauthorized(error.response.status)

            if(error.response.data.data == "This day already has shifts."){

                console.log(error.response.data)
                data = error.response.data

            }else{

                data = false
            }
        })

        return data
    }

    async updateDaysOff(token, id, dayOffData){

        var data;

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        await axios.put(this.url + `/${id}`, dayOffData, config)

            .then(function (response) {

                console.log('UPDATED DAYS OFF: ', response.data.data)
                data = response.data.data

            })

            .catch(function (error) {
                
                StatusService.unauthorized(error.response.status)

                if(error.response.data.data == "This day already has shifts."){

                    console.log(error.response.data)
                    data = error.response.data

                }else{

                    data = false
                }
            })

            return data
    }

    async updateAllDaysOff(token, dayOffData) {

        var data;

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        await axios.post(this.url + '/all', dayOffData, config)

        .then(function (response) {
            
            console.log('DAYS OFF: ', response.data.data)
            data = response.data.data

        })

        .catch(function (error) {

            StatusService.unauthorized(error.response.status)

            if(error.response.data.data == "This day already has shifts."){

                console.log(error.response.data)
                data = error.response.data

            }else{

                data = false
            }
        })

        return data
    }    

}

export default new DaysOff();