<template>
    <div class="main">
        <div class="input-wrapper">
            <div class="checkbox-container">
                <label class="switch">
                    <input type="checkbox" :name="name" :class="[inputState, noIcon]" v-model="checked">
                    <div class="slider">
                        <div class="current">Corrente</div>
                        <div class="next">Successiva</div>
                    </div>
                </label>
            </div>
            <FormInput id="tot-hours" :disabled="(listEdit) ? false : true" v-model:value="num_hours"
                labelText="Monte ore" />
        </div>
        <Scheduler :schedule-date="options.start" :weekNumber="weekNr" :worker="user_id" :year="year" :group_id="null"
            :shops="false" :panoramic="true" />
        <!-- <Button :rounded="true" buttonType="brand button--full-width button--call" text="Amministrazione" icon="account-circle" /> -->
    </div>
</template>

<script setup>

import Scheduler from '../components/Scheduler.vue';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import dayjs from 'dayjs';

dayjs.extend(dayOfYear);
require('dayjs/locale/it');
const weekOfYear = require('dayjs/plugin/weekOfYear');
const weekYear = require('dayjs/plugin/weekYear');
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

</script>

<script>

export default {
    name: 'calendar-view',
    components: {
        Scheduler,
    },

    data() {
        return {
            options: {
                start: dayjs().day(1).format('YYYY-MM-DD')
            },
            checked: false,
            isPanoramic: false,
            schedule_id: null,
            shops: true,
            groupId: null,
            schedulesList: [],
            verify: false,
            shopVerify: false,
            daysOff: false,
            update: false,
            user_id: null,
            weekNr: null,
            year: null
        }
    },
    methods: {
        panoramic(bool) {
            this.isPanoramic = bool
        },
        workers() {
            this.shops = false
        },
        shop() {
            this.shops = true
        },
        verifyConstraints() {
            this.verify = true
        },
        verifyShops() {
            this.shopVerify = true
        },
        close() {
            this.verify = false
            this.shopVerify = false
            this.daysOff = false
        },
    },
    watch: {
        checked(value) {
            if (value == true) {
                this.weekNr++
            }
            else {
                this.weekNr--
            }
        }
    },
    async mounted() {
        this.user_id = localStorage.getItem('user_id')
        this.weekNr = dayjs().week()
        this.year = dayjs().day(6).year()
    }
}

</script>