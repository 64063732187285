<template>
	<div v-if="!camera" class="single-shift-container">
		<div class="single-shift">
			<div class="shift-item day">{{ `Oggi, ${formattedDay}` }}</div>
			<div class="shift-item point">{{ period.worker }}</div>
			<div class="shift-item address">{{ (address(period.id)) ? address(period.id) : 'Nessun indirizzo' }}</div>
			<div class="shift-item start">
				<div>Inizio Turno</div>
				<div>{{ period.time_start }}</div>
			</div>
			<div class="shift-item end">
				<div>Fine Turno</div>
				<div>{{ period.time_end }}</div>
			</div>
			<div class="shift-item scheduled">
				<div>Previsto</div>
				<div>{{ period.tot_hours }} h.</div>
			</div>
			<!-- {{ period }} -->
			<!-- <div class="shift-item fixed"></div> -->
			<!-- <div class="shift-item effective"></div> -->
		</div>

		<Button text="Check In" buttonType="successFull" :rounded="true" icon="qr-code" @click="camera = !camera" />
	</div>
	<QRreader v-if="camera" />
</template>

<script>

import QRreader from '@/components/checkin/QRreader.vue';
import dayjs from 'dayjs';
import { getShop } from '../getters';
import Button from '@/components/tools/Button.vue';

export default {
	name: 'shift-view',
	components: {
		Button,
		QRreader,
	},
	props: {
		period: {
			default: null,
			type: Object
		},
		day: {
			default: null,
			type: String
		}
	},
	data() {
		return {
			camera: false
		}
	},
	methods: {
		address(id) {
			var shop = getShop(id);
			return shop.address
		}
	},
	computed: {
		formattedDay() {
			var day = this.day.split('-').reverse().join('-')
			day = dayjs(day).locale('it').format('dddd DD MMMM')
			day = day.substring(0, 1).toUpperCase() + day.substring(1, day.length)
			return day
		},
	}
}
</script>
