import axios from "axios";
import StatusService from "./statusService";

class Groups{
    constructor(){

        this.url = (process.env.NODE_ENV == 'development' || window.location.origin == 'https://informitalia-client-test.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') 
        ? 'https://informitalia-server-test.quattrolinee.com/api/v1/groups'
        : 'https://informitalia-server.quattrolinee.com/api/v1/groups'

    }

    async addGroup(token, groupData){

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        return await axios.post(this.url, groupData, config)


        .then(function (response) {

            console.log('NOTES DATA: ', response.data.data.id)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async updateGroup(token, groupData, id){

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        return await axios.put(`${this.url}/${id}`, groupData, config)
        

        .then(function (response) {

            console.log('NOTES DATA: ', response.data.data.id)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getGroups(token){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url, config)

        .then(function (response) {

            console.log('GROUPS DATA: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }
}

export default new Groups();