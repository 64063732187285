import axios from "axios"
import StatusService from "./statusService"

class StorePlanningService{
    constructor(){
        this.url = (process.env.NODE_ENV == 'development' || window.location.origin == 'https://informitalia-client-test.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') 
        ? 'https://informitalia-server-test.quattrolinee.com/api/v1/storeplanning'
        : 'https://informitalia-server.quattrolinee.com/api/v1/storeplanning'
    }

    async getScheduleShop(token){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        await axios.get(this.url, config)

        .then(function (response) {

            console.log('SCHEDULE DATA: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async deleteScheduleShop(token, scheduleData){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.post(this.url + '/destroyscheduleandshifts', scheduleData, config)

        .then(function (response) {

            console.log('SCHEDULE DELETED', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getShopsIdArray(token, year, week, groupId){
        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + `/${year}/${week}/${groupId}`, config)

        .then(function (response) {

            const comparator = (a, b) => {
                return a - b
            }

            var data = response.data.data

            data = data.sort(comparator)

            console.log('SHOP IDS: ', data)

            return data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async createStorePlanning(token, storeData){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        return await axios.post(this.url + '/all', storeData, config)

        .then(function (response) {
            
            return response.data.data
        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    // updateAndDelete(array1, array2){

    // }
}

export default new StorePlanningService()