import axios from "axios";
import LocalStorage from './localStorage'
import StatusService from "./statusService";

class Login{

    constructor(){

        this.url = (process.env.NODE_ENV == 'development' || window.location.origin == 'https://informitalia-client-test.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') 
        ? 'https://informitalia-server-test.quattrolinee.com/api/loginapp'
        : 'https://informitalia-server.quattrolinee.com/api/loginapp'

    }

    async userLogin(userData){

        var result;

        return await axios.post(this.url, userData)
        
        .then(function(response){
            LocalStorage.setToken(response.data.data.token)
            
            if(response.data.success == true){

                result = {
                    success: response.data.success,
                    user_id: response.data.data.id
                }

                return result

            }else{

                result = {
                    success: response.data.success,
                    user_id: response.data.data.id
                }

                return result
            }

        })
        
        .catch(function (error){

            console.log(error)
            StatusService.unauthorized(error.response.status)

            result = {
                success: false,
                user_id: null
            }

            return result

        })
    }

}

export default new Login()