import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import Calendar from '@/views/Calendar.vue'
import ResetPassword from '@/views/ResetPassword.vue'


const routes = [
 
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path : '/profilo',
    name : 'Profilo',
    component: Profile
  },
  {
    path : '/turni-programmati',
    name : 'Turni Programmati',
    component: Calendar
  },
  {
    path : '/reset-password',
    name : 'Reset Password',
    component: ResetPassword
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes : routes
})

export default router
